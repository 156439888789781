import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

let queueSelector = document.createElement('div');
queueSelector.id = 'talkative-queue-selector';
document.body.append(queueSelector);

new Vue({
	render: h => h(App),
}).$mount('#talkative-queue-selector')
