<template>
	<div>
		<div
			v-if="selectorType === 'box'"
			@click="loadQueueSelector"
			id="ds-chat-icon"
			:style="wrapperStyle"
			class="tbox-chat-icon tk-hidden"
		>
			<div class="tbox-chat-icon-title">
				{{ queueSelectorTitle }}
			</div>
			<svg class="tbox-chat-icon-svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<path
					d="M12 1c-6.628 0-12 4.573-12 10.213 0 2.39.932 4.591 2.427 6.164l-2.427 5.623 7.563-2.26c9.495 2.598 16.437-3.251 16.437-9.527 0-5.64-5.372-10.213-12-10.213z"></path>
			</svg>
			<svg class="bottom-tri" width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M11 11L1 0.999985L21 0.999987L11 11Z" fill="#e10600"/>
				<path d="M21 0.999987L11 11L1.00003 0.999986" stroke="white" stroke-width="2"/>
			</svg>
		</div>
		<div
			v-if="selectorType === 'bubble'"
			class="tcp-icon"
			@click="loadQueueSelector"
			style="display: block;"
		>
			<div class="tcp-icon-btn" :style="wrapperStyle">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80"><title>Live Chat button</title>
					<path class="icon-fill" d="M52,33.91v4a12,12,0,0,1-12,12H35.35a10,10,0,0,0,8.65,5h1l4.59,4.59A2,2,0,0,0,53,58.08V54.86a10,10,0,0,0,9-10v-1A10,10,0,0,0,52,33.91Z"/>
					<path class="icon-fill" d="M40,23.91H28a10,10,0,0,0-10,10v4a10,10,0,0,0,9,10v3.22a2,2,0,0,0,3.41,1.42L35,47.91h5a10,10,0,0,0,10-10v-4A10,10,0,0,0,40,23.91Z"/>
				</svg>
				<span>{{ queueSelectorTitle }}</span></div>
		</div>

	</div>
</template>

<script>
export default {
	props: {
		wrapperStyle: {
			required: false,
			default: () => 'background: rgb(239, 75, 76)',
			type: String,
		},
		queueSelectorTitle: {
			required: false,
			default: () => 'Live Chat',
		},
		value: {
			required: true,
			type: Object,
		},
	},
	methods: {
		loadQueueSelector() {
			this.$emit('setState', 'queueSelector');
		},
	},
	data() {
		return {
			config: this.value,
		};
	},
	computed: {
		selectorType() {
			return this.config.config.iconType ? this.config.config.iconType : 'box';
		}
	},
};
</script>

<style scoped>
@media only screen and (min-width: 600px) {
	.tbox-chat-icon {
		position: fixed;
		bottom: 5%;
		right: 5%;
		max-width: 50em;
		min-width: 5em;
		width: unset !important;
		height: unset !important;
		border-radius: unset !important;
		text-align: center;
		background-color: rgb(204, 9, 47);
		padding: 0.5em 1.5em;
		font-size: 1em;
		color: white;
		text-transform: uppercase;
		font-weight: bold;
		cursor: pointer;
		outline: 1px solid #fff;
	}


	.tbox-chat-icon-svg {
		display: none;
	}

	.tbox-chat-icon-title {
		display: block !important;
	}

	.bottom-tri {
		display: block !important;
	}

}

.tbox-chat-icon {
	position: fixed;
	bottom: 5%;
	right: 5%;
	background: none;
	background-color: rgba(0, 0, 0, 0);
	background-color: #e10600;
	color: #fff;
	height: 48px;
	width: 48px;
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	cursor: pointer;
	border: 1px solid #fff;
}

.tbox-chat-icon-svg {
	fill: #fff;
}

.tbox-chat-icon-title {
	display: none;
}

.bottom-tri {
	display: none;
	position: absolute;
	top: calc(100% - 1px);
	right: 16px;
}
</style>
