export default class EcsBuilder {

	ecsConfig;
	fallback;

	constructor(ecsConfig, fallback = false) {
		this.ecsConfig = ecsConfig;
		this.fallback = fallback
	}

	build() {
		let talkativeAppElement = document.createElement('talkative-engage');
		talkativeAppElement.id = 'talkative-engage';
		talkativeAppElement.setAttribute('company-uuid', this.ecsConfig.companyUuid);
		talkativeAppElement.setAttribute('queue-uuid', this.ecsConfig.queues[0].uuid);
		talkativeAppElement.setAttribute('primary-color', this.ecsConfig.primaryColor);
		talkativeAppElement.setAttribute(':api-features', '["chat", "video"]');
		talkativeAppElement.setAttribute('region', this.ecsConfig.region);
		if (this.ecsConfig.chatWaitMessages) {
			talkativeAppElement.setAttribute(':chat-wait-messages', JSON.stringify(this.ecsConfig.chatWaitMessages));
		}
		if (this.ecsConfig.chatWaitQueueStatistics) {
			talkativeAppElement.setAttribute(':chat-wait-queue-statistics', JSON.stringify(this.ecsConfig.chatWaitQueueStatistics));
		}
		if (this.ecsConfig.contentStrings) {
			talkativeAppElement.setAttribute(':content-strings', JSON.stringify(this.ecsConfig.contentStrings));
		}
		if (this.ecsConfig.dataCollection) {
			talkativeAppElement.setAttribute(':collect-data', JSON.stringify(this.ecsConfig.dataCollection));
		}
		if (this.ecsConfig.privacyPolicyUrl) {
			talkativeAppElement.setAttribute('privacy-policy-url', this.ecsConfig.privacyPolicyUrl);
		}
		if (this.ecsConfig.ga) {
			talkativeAppElement.setAttribute(':ga', this.ecsConfig.ga);
		}
		if (this.ecsConfig.midChatFeedback) {
			talkativeAppElement.setAttribute(':mid-chat-feedback', this.ecsConfig.midChatFeedback);
		} else {
			talkativeAppElement.setAttribute(':mid-chat-feedback', false);
		}
		document.body.appendChild(talkativeAppElement);
		return this;
	}

	loadAssets() {
		let talkativeCssLink = document.createElement('link');
		talkativeCssLink.setAttribute('rel', 'stylesheet');
		talkativeCssLink.setAttribute('href', `https://talkative-cdn.com/ecs/${this.ecsConfig.ecsVersion}/talkative-engage.css`);
		document.head.appendChild(talkativeCssLink);
		let talkativeScriptElement = document.createElement('script');
		talkativeScriptElement.setAttribute('src', `https://talkative-cdn.com/ecs/${this.ecsConfig.ecsVersion}/talkative-engage.js`);
		document.body.appendChild(talkativeScriptElement);
		return this;
	}
}
