import axios from 'axios';

export default class QueueManager {
	queues;
	fallbackQueues;
	config;
	engageUris;
	
	constructor(config) {
		this.queues = config.queues;
		this.fallbackQueues = config.fallback?.queues ?? null;
		this.config = config;
		this.engageUris = {
			default: 'https://engage.talkative.uk',
			au: 'https://au.engage.app',
			us: 'https://us.engage.app',
			eu: 'https://eu.engage.app',
			dev: 'http://localhost:8080',
		};
	}
	
	async getQueueStatus(queueUuid, companyUuid) {
		return this.onlineRequest(queueUuid, companyUuid, this.config.ecsVersion)
			.then(({ data }) => {
				return data;
			})
			.catch((error) => {
				console.error('Cannot query queue', error);
			});
	}
	
	async onlineRequest(queueUuid, companyUuid, ecsVersion) {
		return await axios.post(`${this.getEngageUri()}/api/v1/controls/online`, {
			talkative_version: ecsVersion,
			talkative_company_uuid: companyUuid,
			talkative_queue_uuid: queueUuid,
			talkative_qos: {}
		});
	}
	
	
	getEngageUri() {
		return this.engageUris[this.config.region ?? 'eu'];
	}
	
	getOnlineQueues() {
		return new Promise((resolve) => {
				this.getDefaultQueues()
					.then(() => {
						if (this.config.fallback && this.allQueuesOffline()) {
							this.getFallbackQueues()
								.then(() => {
									this.config.companyUuid = this.config.fallback.companyUuid;
									this.config.queues = this.config.fallback.queues;
									resolve();
								});
						} else {
							resolve();
						}
					});
			}
		);
	}
	
	async getDefaultQueues() {
		let promises = this.queues
			.map((queue, key) => {
				return this.getQueueStatus(queue.uuid, this.config.companyUuid)
					.then((data) => {
						this.queues[key].features = data?.features ?? null;
						this.queues[key].status = data?.status ?? 'offline';
						if (this.config.presenceOverride && this.config.presenceOverride === true) {
							this.queues[key].agentsAvailable = true;
							this.queues[key].features = {
								chat: true,
								video: true,
							};
						} else {
							this.queues[key].agentsAvailable = data.features?.chat ?? false;
							this.queues[key].features = data?.features ?? null;
						}
						
						return;
					});
			});
		
		return Promise.all(promises);
	}
	
	async getFallbackQueues() {
		this.queues = this.fallbackQueues;
		let promises = this.queues
			.map((queue, key) => {
				return this.getQueueStatus(queue.uuid, this.config.fallback.companyUuid)
					.then((data) => {
						this.queues[key].features = data?.features ?? null;
						this.queues[key].status = data?.status ?? 'offline';
						this.queues[key].agentsAvailable = data.features?.chat ?? false;
						return;
					});
			});
		
		return Promise.all(promises);
		
	}
	
	allQueuesOffline() {
		return !this.queues.filter(x => x.features.chat).length;
	}
	
	
}
