<template>
	<div id="ds-queue-list" class="ds-queue-selector-body">
		<div style="margin: 5px 0;">
			{{ serviceSelectorMessage }}
		</div>
		<queue
			:style="wrapperStyle"
			v-for="(queue, key) in onlineQueues"
			:key="key"
			:queue="queue"
			@loadQueue="loadQueue(queue.uuid)"
		/>
	</div>
</template>

<script>
import Queue from '@/components/Queue';
export default {
	components: {
		Queue,
	},
	props: {
		onlineQueues: {
			type: Array,
			required: true,
		},
		wrapperStyle: {
			type: String,
			required: false,
			default: () => '',
		},
		serviceSelectorMessage: {
			type: String,
			required: true,
		},
	},
	methods: {
		loadQueue(uuid) {
			this.$emit('loadQueue', uuid);
		}
	}
};
</script>

<style scoped>

</style>
