<template>
	<div v-if="visible" id="ds-queue-selector" class="ds-selector">
		<div
			:style="wrapperStyle"
			class="ds-selector-header"
			@click="setState('standby')"
			v-text="queueSelectorTitle"
		/>
		<div v-if="onlineQueues.length">
			<component
				:is="selectorType"
				:online-queues="onlineQueues"
				:wrapper-style="wrapperStyle"
				:service-selector-message="serviceSelectorMessage"
				@loadQueue="loadQueue"
			/>
		</div>
		<div
			v-else
			v-text="queuesOffline"
		/>

		<div class="ds-close ds-selector-footer" @click="setState('standby')">
			<div
				class="ds-close-cross"
			>
				x
			</div>
		</div>
	</div>
</template>

<script>
import Queue from '@/components/Queue';
import QueueDropdown from '@/components/QueueSelector/QueueDropdown';
import QueueList from '@/components/QueueSelector/QueueList';
import QueueMapper from '@/services/QueueMapper';

export default {
	components: {
		Queue,
		QueueDropdown,
		QueueList,
	},
	props: {
		wrapperStyle: {
			required: false,
			default: () => 'background: rgb(239, 75, 76)',
			type: String,
		},
		value: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			data: this.value,
			visible: false,
			queueMapper: new QueueMapper(this.value),
			mappedQueues: null,
		};
	},
	computed: {
		onlineQueues() {
			return this.mappedQueues && this.mappedQueues.length ? this.mappedQueues : this.data.config.queues.filter((x) => x.features.chat === true);
		},
		serviceSelectorMessage() {
			return this.data.config.serviceSelectorMessage ?? 'Please select a queue from the list below';
		},
		queueSelectorTitle() {
			return this.data.config.queueSelectorTitle ?? 'LIVE CHAT';
		},
		queuesOffline() {
			return this.data.config.offlineMessage ?? 'Sorry, all of are agents are currently busy. Please try again later.';
		},
		selectorType() {
			return this.data.config.selectorType === 'dropdown' && this.onlineQueues.length !== 1 ? QueueDropdown : QueueList;
		},
	},
	methods: {
		loadQueue(queueUuid) {
			let uuid;
			if (typeof queueUuid === 'object') {
				uuid = queueUuid.uuid;
			} else {
				uuid = queueUuid;
			}
			window.TalkativeEngageApi.updateConfig({ queueUuid: uuid })
				.then(() => {
					localStorage.setItem('tk_interaction_active', 'true');
					this.data.interactionActive = true;
					if (this.data.config.presenceOverride && this.data.config.presenceOverride === true) {
						window.TalkativeEngageApi.startChat(true);
					} else {
						window.TalkativeEngageApi.startChat();
					}
				});
		},
		setState(state) {
			this.data.state = state;
		},
	},
	mounted() {
		this.queueMapper.load()
			.then(() => {
				this.mappedQueues = this.queueMapper.map().flat();
			})
			.catch((response) => {
				console.debug('failed to load', response)
			})
			.finally(() => {
				if (this.data.config.skipQueueSelection && this.data.config.skipQueueSelection === true && this.data.queueManager.queues.length === 1) {
					this.loadQueue(this.onlineQueues[0].queueUuid);
				} else {
					this.visible = true;
				}
			});
	}
};
</script>

<style scoped>

.ds-selector {
	background: #f2f1ed;
	position: fixed;
	bottom: 0;
	right: 5%;
	width: 300px;
	border-radius: 5px;
	text-align: center;
	font-size: 12pt;
	z-index: 9999;
	border: 1px solid #fff;
}

.ds-selector-header {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	color: #fff;
	padding: 5px 0;
	text-transform: uppercase;
	cursor: pointer;
}

.ds-queue-selector-body {
	padding: 15px 0;
}

.ds-item {
	background: grey;
	color: #fff;
	padding: 2px 0;
	margin: 5px 5px;
	text-align: center;
	cursor: pointer;
}

.ds-feature-button {
	color: #fff;
	padding: 5px 0;
	font-size: 12pt;
	margin: 5px 10px;
	text-align: center;
	cursor: pointer;
	width: calc(100% - 20px);
}

.ds-selector-footer {
	padding: 0 15px 5px;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	color: grey;
	text-align: right;
	cursor: pointer;
}

.ds-close-cross {
	line-height: 15pt;
}

.ds-bold {
	font-weight: bold;
}

.p-2 {
	padding: 0 5px;
}

@media only screen and (max-width: 600px) {
	.ds-selector {
		width: 100%;
		margin: 0;
		right: 0;
	}
}
</style>
