import axios from 'axios';

export default class QueueMapper {
	rules;
	config;
	
	types = {
		class: this.class,
		path: this.path,
	};
	
	targets = {
		href: this.parseHrefLink,
		path: this.parseUriPath,
	};
	
	constructor(config) {
		this.config = config;
		this.rules = null;
	}
	
	async load() {
		if (!this.config.config.router) {
			return Promise.reject('No routing settings have been provided');
		}
		const randomNumber = Math.floor((Math.random() * 100000) + 1);
		return axios.get(this.config.config.router + '?c=' + randomNumber)
			.then(({ data }) => {
				this.rules = data;
			});
	}
	
	map() {
		let mappings = [];
		Object.values(this.rules)
			.forEach((rule) => {
				mappings.push(this.types[rule.type].call(this, rule.identifier, rule.target, rule.mappings));
			});
		
		return mappings;
	}
	
	class(identifier, target, mappings) {
		const key = this.targets[target].call(this, identifier);
		
		let queues = mappings.filter((x) => x.key.includes(key));
		
		return queues.filter((x) => {
			return this.config.queueManager.getQueueStatus(x.uuid)
				.then((data) => {
					return data.features.chat === true;
				});
		});
	}
	
	path(identifier, target, mappings) {
		const key = this.targets[target].call(this, identifier).substring(1);
		
		if (!key) {
			return [];
		}
		
		let queues = mappings.filter((x) => x.key.includes(key));
		
		return queues.filter((x) => {
			return this.config.queueManager.getQueueStatus(x.uuid)
				.then((data) => {
					return data.features.chat === true;
				});
		});
	}
	
	parseHrefLink(identifier) {
		const element = document.getElementsByClassName(identifier) ? document.getElementsByClassName(identifier)[0] : null;
		if (!element) {
			return;
		}
		return new URL(element.href).pathname;
	}
	
	parseUriPath(identifier) {
		return window.location[identifier];
	}
}
