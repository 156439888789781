<template>
	<div>
		<transition name="nudge-fade">
		<div class="default-nudge" style="right: 10%;" :style="borderTopStyle" v-if="!dismissedForSession && showNudge">
			<button @click="dismissNudge" aria-label="Close Nudge" aria-hidden="true" class="default-nudge__close">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.414 9.414"><title>Close Button</title>
					<g transform="translate(-359.793 -675.793)">
						<path transform="translate(360.5 676.5)" d="M0 0l8 8"></path>
						<path transform="translate(360.5 676.5)" d="M8 0L0 8"></path>
					</g>
				</svg>
			</button>
			<h3 class="default-nudge__heading" :style="primaryColourStyle">
				Hi there!
			</h3>
			<p class="default-nudge__text">
				Our friendly support team is online and ready to help!
			</p>
			<div class="tk_nudge_buttons">
				<button @click="nudgeAction" aria-label="Start Interaction" aria-hidden="true" class="default-nudge__button" :style="backgroundAndBorderStyle">
					Yes, please!
				</button>
				<button @click="dismissNudge" aria-label="Close Nudge" aria-hidden="true" class="default-nudge__button default-nudge__button--outline" :style="primaryColourStyle">
					No, thanks.
				</button>
			</div>
		</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			required: true,
			type: Object,
		},
	},
	data() {
		return {
			data: this.value,
			showNudge: false,
		};
	},
	computed: {
		primaryColourStyle() {
			return 'color: rgb(' + this.data.config.primaryColor + ')';
		},
		borderTopStyle() {
			return 'border-top: rgb(' + this.data.config.primaryColor + ')';
		},
		backgroundAndBorderStyle() {
			return 'background-color: rgb(' + this.data.config.primaryColor + '); border-color: rgb(' + this.data.config.primaryColor + ')';
		},
		dismissedForSession() {
			return sessionStorage.getItem('talkative-nudge-dismissed') === 'true';
		},

	},
	methods: {
		nudgeActions(type) {
			const action = {
				time: this.timerNudge
			}

			return action[type];
		},
		nudgeAction() {
			this.showNudge = false;
			window.TalkativeEngageApi.startChat();
		},
		dismissNudge() {
			sessionStorage.setItem('talkative-nudge-dismissed', 'true');
			this.showNudge = false;
		},
		timerNudge(time) {
			setTimeout(() => {
				this.showNudge = true;
			}, time * 1000)
		}
	},
	mounted() {
		this.data.config.nudgeConfiguration.rules.forEach((rule) => {
			return this.nudgeActions(rule.type)(rule.trigger)
		});
	},
};
</script>

<style scoped>
.nudge-fade-enter-active,
.nudge-fade-leave-active {
	transition: opacity .5s, -webkit-transform .5s;
	transition: opacity .5s, transform .5s;
	transition: opacity .5s, transform .5s, -webkit-transform .5s
}

.nudge-fade-enter,
.nudge-fade-leave-to {
	opacity: 0;
	-webkit-transform: translateY(-.5em);
	transform: translateY(-.5em)
}

.tk_nudge_buttons {
	display: flex;
	justify-content: space-evenly;
}

.default-nudge,
.default-nudge :after,
.default-nudge :before {
	box-sizing: inherit
}

.default-nudge,
button {
	font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif
}

.default-nudge {
	box-sizing: border-box;
	z-index: 9999999;
	width: 240px;
	position: fixed;
	bottom: 80px;
	border-top: 4px solid #000;
	border-radius: .25em;
	overflow: auto;
	background-color: #fff;
	padding: 1em;
	box-shadow: 0 .125em .5em rgba(0, 0, 0, .2)
}

.default-nudge__close {
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 0;
	width: 36px;
	height: 36px;
	padding: .8em;
	cursor: pointer
}

.default-nudge__close svg path {
	fill: none;
	stroke: #aaa;
	stroke-width: 1px
}

.default-nudge__heading {
	font-size: 1.2em;
	margin: .25em 0
}

.default-nudge__text {
	font-size: .8em;
	margin: 0 0 1em;
	color: #333
}

.default-nudge__button {
	font-size: .8em;
	border: 2px solid #000;
	background: none;
	background-color: #000;
	color: #fff;
	border-radius: .25em;
	padding: .5em .8em;
	cursor: pointer
}

.default-nudge__button--outline {
	border-color: #000;
	background-color: #fff;
	color: #000
}

svg {
	width: 100%
}

@media screen and (min-width: 768px) {
	.default-nudge {
		width: 275px
	}

	.default-nudge__heading {
		font-size: 1.5em
	}

	.default-nudge__button,
	.default-nudge__text {
		font-size: 1em
	}
}
</style>
