export default class TalkativeQueueSelectorApi {
	constructor({ init, readyCallback, load, toggle, startChat }) {
		this.load = load;
		this.init = init;
		this.readyCallback = (typeof readyCallback === 'function') ? readyCallback : () => {};
		this.toggle = toggle;
		this.startChat = startChat;
	}

}
