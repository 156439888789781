<template>
	<div
		v-if="data.visible && !interactionInSession"
	>
		<nudge
			v-if="nudgeConfigured"
			v-model="data"
		/>
		<component
			:is="activeComponent"
			:wrapperStyle="wrapperStyle"
			v-model="data"
			@setState="setState"
		/>
	</div>
</template>

<script>
import Standby from '@/components/Standby';
import EcsBuilder from '@/services/EcsBuilder';
import QueueSelector from '@/components/QueueSelector';
import TalkativeQueueSelectorApi from '@/services/TalkativeQueueSelectorApi';
import QueueManager from '@/services/QueueManager';
import Nudge from '@/components/Nudge';
import StyleManager from '@/services/StyleManager';

export default {
	components: {
		Nudge,
		Standby,
		QueueSelector,
	},
	data() {
		return {
			trigger: false,
			data: {
				states: [
					{
						key: 'standby',
						component: Standby,
					},
					{
						key: 'queueSelector',
						component: QueueSelector,
					}
				],
				state: 'standby',
				config: {},
				ecsBuilder: null,
				queueManager: null,
				styleManager: null,
				interactionActive: false,
				visible: false,
				selectorType: 'list',
			},
		};
	},
	methods: {
		setState(state) {
			this.data.state = state;
		},
		init(config) {
			this.data.config = config;

			//initial the style manager
			this.data.styleManager = new StyleManager();

			this.data.queueManager = new QueueManager(this.data.config);
			this.data.queueManager.getOnlineQueues()
				.then(() => {
					this.data.ecsBuilder = new EcsBuilder(this.data.config);
					this.data.ecsBuilder.build()
						.loadAssets();

					if (this.data.config.hideFeedback && this.data.config.hideFeedback === true) {
						this.data.styleManager
							.addStyle('.feedback-wrapper', [{ attribute: 'display',	value: 'none' }]);
					}

					if (this.data.config.hideTranscript && this.data.config.hideTranscript === true) {
						this.data.styleManager
							.addStyle('.chatlogs-wrapper', [{ attribute: 'display',	value: 'none' }]);
					}

					this.data.styleManager.build().apply();
				});
		},
		load(skipStandby = false) {
			this.data.visible = true;
			if (skipStandby) {
				this.setState('queueSelector');
			}
		},
		toggle() {
			this.data.visible = !this.data.visible;
		},
		startChat() {
			window.TalkativeEngageApi.startChat();
		},
	},
	computed: {
		wrapperStyle() {
			return `background: rgb(${this.data.config.primaryColor})`;
		},
		activeComponent() {
			return this.data.states.filter((x) => x.key === this.data.state)[0].component;
		},
		interactionInSession() {
			// eslint-disable-next-line no-unused-vars
			const pointless = this.trigger;
			return localStorage.getItem('tk_interaction_active') === 'true' || this.data.interactionActive;
		},
		nudgeConfigured() {
			return this.data.config.nudgeConfiguration && this.data.config.nudgeConfiguration.active;
		}
	},
	created() {
		const api = window.TalkativeQueueSelectorApi;
		window.TalkativeQueueSelectorApi = new TalkativeQueueSelectorApi({
			init: this.init,
			readyCallback: (typeof api !== 'undefined' && typeof api.readyCallback === 'function') ? api.readyCallback : null,
			load: this.load,
			toggle: this.toggle,
			startChat: this.startChat,
		});
		window.TalkativeQueueSelectorApi.readyCallback();

		window.TalkativeEngageApi = {
			readyCallback: () => {
				if (window.TalkativeEngageApi.isActive()) {
					this.data.interactionActive = true;
					localStorage.setItem('tk_interaction_active', 'true');
					this.trigger = !this.trigger;
				} else {
					console.log('setting state to standby');
					this.data.interactionActive = false;
					localStorage.setItem('tk_interaction_active', 'false');
					this.trigger = !this.trigger;
					this.setState('standby');
					this.data.visible = !this.data.queueManager.allQueuesOffline();
				}
			},
			preInteractionCancelledCallback: () => {
				this.data.interactionActive = false;
				const target = this.data.queueManager.queues.length === 1 ? 'standby' : 'queueSelector';
				this.setState(target);
				localStorage.setItem('tk_interaction_active', 'false');
				this.trigger = !this.trigger;
			},
		};
	}
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}
</style>
