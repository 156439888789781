<template>
	<div id="ds-queue-list" class="ds-queue-selector-body">
		<div style="margin: 5px 0;">
			{{ serviceSelectorMessage }}
		</div>
		<select v-model="queue" class="tk-queue-selector">
			<option value="">Select a Queue</option>
			<option
				v-for="(queue, key) in onlineQueues"
				:value="queue"
				:key="key"
				v-text="queue.name"
			/>
		</select>
		<button
			class="tk-btn"
			:style="wrapperStyle"
			@click="loadQueue"
			:disabled="selectDisabled"
		>
			Select Queue
		</button>

	</div>
</template>

<script>
export default {
	props: {
		onlineQueues: {
			type: Array,
			required: true,
		},
		wrapperStyle: {
			type: String,
			required: false,
			default: () => '',
		},
		serviceSelectorMessage: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			queue: '',
		};
	},
	computed: {
		selectDisabled() {
			return this.queue === '';
		},
	},
	methods: {
		loadQueue() {
			this.$emit('loadQueue', this.queue);
		}
	}
};
</script>

<style>
	.tk-queue-selector {
		margin: 5px auto;
		padding: 10px;
		width: calc(100% - 30px);
	}

	.tk-btn {
		margin: 5px auto;
		padding: 10px;
		width: calc(100% - 30px);
		color: #fff;
		cursor: pointer;
	}
</style>
