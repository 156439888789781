<template>
	<button id="ds-queue-list" class="ds-queue" @click="loadQueue">
		{{ queue.name }}
	</button>
</template>

<script>
export default {
	props: {
		queue: {
			type: Object,
			required: true,
		},
	},
	methods: {
		loadQueue() {
			this.$emit('loadQueue', this.queue.uuid);
		}
	}
}
</script>

<style scoped>
	.ds-queue {
		margin: 5px 10px;
		width: calc(100% - 40px);
		padding: 10px;
		color: #fff;
		cursor: pointer;
	}
</style>
