export default class StyleManager {
	styles;
	element;
	styleNode;
	
	constructor() {
		this.styles = [];
		this.element = document.createElement('style');
	}
	
	addStyle(key, styles) {
		this.styles.push({key, styles});
		return this;
	}
	
	build() {
		let consolidatedStyles = this.styles.map((key) => {
			return ' ' + key.key + ' { ' +
				key.styles.map((x) => {
					return x.attribute + ': ' + x.value + '; ';
				}).join('')
			+ '}';
		}).join('');
		this.styleNode = document.createTextNode(consolidatedStyles);
		return this;
	}
	
	apply() {
		this.element.appendChild(this.styleNode);
		document.head.appendChild(this.element);
	}
}
